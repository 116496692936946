.ProfileImage {
  --profile-size: 150px;
  width: var(--profile-size);
  height: var(--profile-size);
  transition: all 0.6s linear;
}

.ProfilePanel:hover .ProfileImage {
  border-color: var(--bg-dark-violet);
}

.ProfileImage > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(0.8) translateX(-20px);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.ProfilePanel:hover .ProfileImage > img {
  transform: scale(0.8) translateX(20px);
}

.ProfileDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 15px;
}

.ProfilePresentation {
  padding: 15px;
  transition: all 0.6s linear;
  border-left: 1px solid var(--bg-light-mid-transparent);
  border-top: 1px solid var(--bg-light-mid-transparent);
}

.ProfilePresentation > h3 {
  display: block;
  font-size: 1.6em;
  opacity: 0.4;
  transition: opacity 0.6s;
  margin: 25px 0;
}

.ProfilePanel:hover .ProfilePresentation {
  border-color: var(--bg-light-transparent);
  background-color: var(--bg-light-transparent);
}

.ProfilePanel:hover .ProfilePresentation > h3 {
  opacity: 1;
}

.PresentationImportant > li {
  opacity: 0.5;
  transition: opacity 0.3s;
}

.PresentationImportant > li > svg {
  opacity: 0;
  transition: opacity 0.3s;
  color: var(--bg-violet);
}

.ProfilePanel:hover .PresentationImportant > li > svg {
  opacity: 1;
}

.ProfilePanel:hover .PresentationImportant > li {
  opacity: 1;
}

.PresentationImportant > li > span {
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: inline-block;
  transform: translateX(-10px);
}

.ProfilePanel:hover .PresentationImportant > li > span {
  transform: translateX(10px);
}

.PresentationLinks > li {
  transition: opacity 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0.5;
}

.PresentationLinks > li > a {
  width: 100%;
  display: block;
  cursor: pointer;
}

.PresentationLinks > li span {
  margin-left: 10px;
  display: inline-block;
  cursor: pointer !important;
}

.ProfilePanel:hover .PresentationLinks > li {
  opacity: 1;
  cursor: pointer !important;
}

.ProfilePanel .PresentationLinks > li:not(:hover) > a > svg {
  color: white !important;
}

@media (min-width: 701px) {
  .ProfilePanel:not(:hover) .Tag svg {
    transform: rotateY(360deg);
    color: rgba(255, 255, 255, 0.5) !important;
  }
}

@media (max-width: 700px) {
  .ProfileImage {
    --profile-size: 100px;
  }
  .ProfileImage > img,
  .ProfilePanel:hover .ProfileImage > img {
    transform: scale(0.8);
  }
  .ProfilePresentation > h3 {
    font-size: 1.2em;
    margin: 10px 0;
  }
  .ProfilePanel > ul > li:nth-child(1) {
    padding: 15px 0;
  }
}

.CV h3 {
  font-size: 1.6em;
  margin-bottom: 15px;
}

.CV > ul > li {
  opacity: 0.5;
  transition: opacity 0.5s;
  padding: 25px;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.CV > ul > li:hover {
  opacity: 1;
  background: var(--bg-light-transparent);
  background: linear-gradient(
    90deg,
    var(--bg-light-transparent) 49%,
    rgba(122, 95, 240, 0) 98%
  );
}

.ImagePlaceholder {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-dark-mid-transparent);
  border: 1px solid var(--bg-light-mid-transparent);
  border-radius: 4px;
}

.MissionArrow {
  transform: translateY(-3px);
}

.SchoolLogo {
  width: 48px;
  border-radius: 4px;
}

.SchoolDescription > label {
  font-size: 1em;
}

.SchoolDescription {
  margin-left: 10px;
}

.SchoolDescription > span,
.MissionPeriode {
  font-size: 0.8em;
  display: inline-block;
  color: gray;
  font-weight: bold;
}

.MissionRealisation svg {
  color: var(--bg-violet);
}

.SchoolDate {
  font-size: 0.7em;
  font-style: italic;
  margin-right: 10px;
}

.ExperiencesMissions {
  margin-top: 15px;
}

.ExperiencesMissions > li {
  padding: 15px;
  border-left: 1px solid var(--bg-light-mid-transparent);
  border-bottom: 1px solid var(--bg-light-mid-transparent);
  background-color: var(--bg-dark-light-transparent);
  margin-bottom: 15px;
}

.ExperiencesMissions h4 {
  font-size: 1em;
  color: var(--bg-violet);
}

.ExperienceDateBlock {
  --date-size: 140px;
}

.ExperienceDateBlock > li:nth-child(1) {
  width: var(--date-size);
}

.ExperienceDateBlock > li:nth-child(2) {
  width: calc(100% - var(--date-size));
}

@media (max-width: 1000px) {
  .ExperienceDateBlock > li:nth-child(1) {
    display: none;
  }
  .ExperienceDateBlock > li:nth-child(2) {
    width: calc(100%);
  }
}

@media (max-width: 700px) {
  .CV > ul > li {
    padding: 15px !important;
  }
  .CV > ul {
    padding: 0px;
  }
  .CV h3 {
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  .ExperiencesMissions {
    margin-top: 5px !important;
  }

  .SchoolDescription > label {
    font-size: 0.9em;
  }

  .CV p {
    font-size: 0.9em;
  }

  .SchoolLogo {
    width: 42px;
  }
  .ImagePlaceholder {
    width: 42px;
    height: 42px;
  }
}

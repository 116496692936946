@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&family=Roboto+Slab:wght@100..900&display=swap");

:root {
  --bg-dark: #22242b;
  --bg-light: #272931;
  --bg-light-mid-transparent: rgba(255, 255, 255, 0.1);
  --bg-light-transparent: rgba(255, 255, 255, 0.02);
  --bg-dark-transparent: rgba(1, 1, 1, 0.3);
  --bg-dark-light-transparent: rgba(1, 1, 1, 0.05);
  --bg-violet: #a561d3;
  --bg-dark-violet: #6e3e8d;
}

* {
  cursor: crosshair;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Mukta", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-dark);
  color: white;
  font-size: 18px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.SecondaryFont,
h1,
h2,
h3,
h4 {
  font-family: "Roboto Slab", serif;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  line-height: 1.2;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer !important;
}

.HiddenScrollbar {
  overflow: auto; /* Permet le défilement */
  scrollbar-width: none; /* Pour Firefox */
  -ms-overflow-style: none; /* Pour IE et Edge */
}

.HiddenScrollbar::-webkit-scrollbar {
  display: none; /* Pour Chrome, Safari et Opera */
}

.Tag {
  display: flex;
  position: relative;
  margin: 5px;
  align-items: center;
  transition: background-color 0.5s,
    transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: rotateY(0deg);
  --x: 50%;
  --y: 50%;
  background: radial-gradient(
    circle at var(--x) var(--y),
    rgba(255, 255, 255, 0.1),
    transparent
  );
  padding: 0px 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.Tag > li {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.Tag > li > svg {
  font-size: 20px;
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (max-width: 700px) {
  .Tag {
    font-size: 0.9em;
  }
  .Tag > li > svg {
    font-size: 16px;
  }
}

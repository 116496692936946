main {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  cursor: crosshair;
}

main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../public/bg.svg");
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  pointer-events: none;
  transition: opacity 0.3s ease;
  mask-image: radial-gradient(
    circle 400px at var(--mouse-x, 50%) var(--mouse-y, 50%),
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 60%,
    rgba(0, 0, 0, 0.25) 100%
  );
  z-index: -1;
  pointer-events: none;
}

main:hover::before {
  opacity: 0.3;
}

@media (max-width: 700px) {
  main::before {
    background-size: contain;
  }
}

.Home {
  max-width: 1600px;
}

.Home > li:nth-child(1) {
  width: 50%;
}

.Home > li:nth-child(2) {
  width: 50%;
}

@media (max-width: 1300px) {
  .Home > li:nth-child(1) {
    width: 40%;
    padding-top: 15px !important;
  }

  .ProfileDescription > p:nth-child(2) {
    font-size: 2.5em !important;
    margin-top: 10px;
  }

  .Home > li:nth-child(2) {
    width: 60%;
  }
}

@media (max-width: 700px) {
  .Home {
    flex-direction: column !important;
    overflow: auto;
    height: auto !important;
    width: 100% !important;
  }
  .Home > li {
    width: 100% !important;
    height: auto !important;
    max-height: inherit;
    padding: 0 !important;
  }
  .Home > li:nth-child(1) {
    padding-top: 0px !important;
  }
}
